var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container-bg rounded elevation-3",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify-md":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('h2',[_vm._v("Purchase Orders")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex flex-row justify-end align-center"},[_c('search-box',{on:{"on-search":function($event){return _vm.getItems(true)}},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}}),_c('v-btn',{staticClass:"ml-2 mb-1",attrs:{"color":"success","disabled":_vm.loading,"to":"/purchases/orders/create?type=RM"}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-per-page":_vm.query.limit,"loading":_vm.loading,"show-select":"","item-key":"id","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.bcSync",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.bcSync && item.bcSync.sync && item.bcSync.bcLog)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle-outline ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-sync-alert ")])]}}],null,true)},[(item.bcSync && item.bcSync.updatedAt)?_c('span',[_vm._v(" Last sync date "+_vm._s(_vm._f("dateTimeUserFormat")(item.bcSync.updatedAt))+" ")]):_c('span',[_vm._v(" Not sync yet ")])])]}},{key:"item.vendor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.vendorNumber) + " " + (item.vendorName)))+" ")]}},{key:"item.documentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateUserFormat")(item.documentDate))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mapState(item.state))+" ")]}},{key:"item.approver",fn:function(ref){
var item = ref.item;
return [(item.approver)?_c('span',[_vm._v(" "+_vm._s(item.approver)+" ("+_vm._s(_vm._f("dateUserFormat")(item.approvedDate))+") ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.receiver",fn:function(ref){
var item = ref.item;
return [(item.receiver)?_c('span',[_vm._v(" "+_vm._s(item.receiver)+" ("+_vm._s(_vm._f("dateUserFormat")(item.receivedDate))+") ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","small":"","icon":"","to":("/purchases/orders/" + (item.id) + "/pdf")}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-magnify ")])],1),_c('v-btn',{attrs:{"color":"secondary","small":"","icon":"","to":("/purchases/orders/" + (item.id) + "?type=RM")}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-square-edit-outline ")])],1),_c('v-btn',{attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.confirmRemoveItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true),model:{value:(_vm.itemSelect),callback:function ($$v) {_vm.itemSelect=$$v},expression:"itemSelect"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"disabled":_vm.loading,"total-visible":"7","color":"secondary"},model:{value:(_vm.query.page),callback:function ($$v) {_vm.$set(_vm.query, "page", $$v)},expression:"query.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }