<template>
  <v-container
    fluid
    class="container-bg rounded elevation-3">
    <v-row justify-md="space-between">
      <v-col
        cols="12"
        md="4">
        <h2>Purchase Orders</h2>
      </v-col>

      <v-spacer />

      <v-col
        cols="12"
        md="6">
        <div class="d-flex flex-row justify-end align-center">
          <search-box
            v-model="query.search"
            @on-search="getItems(true)" />
          <v-btn
            color="success"
            :disabled="loading"
            to="/purchases/orders/create?type=RM"
            class="ml-2 mb-1">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="itemSelect"
          :headers="headers"
          :items="items"
          :item-per-page="query.limit"
          :loading="loading"
          show-select
          item-key="id"
          hide-default-footer>
          <template #[`item.bcSync`]="{ item }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-if="item.bcSync && item.bcSync.sync && item.bcSync.bcLog"
                  v-bind="attrs"
                  color="success"
                  v-on="on">
                  mdi-check-circle-outline
                </v-icon>
                <v-icon
                  v-else
                  v-bind="attrs"
                  color="error"
                  v-on="on">
                  mdi-sync-alert
                </v-icon>
              </template>
              <span v-if="item.bcSync && item.bcSync.updatedAt">
                Last sync date {{ item.bcSync.updatedAt | dateTimeUserFormat() }}
              </span>
              <span v-else>
                Not sync yet
              </span>
            </v-tooltip>
          </template>
          <template #[`item.vendor`]="{ item }">
            {{ `${item.vendorNumber} ${item.vendorName}` }}
          </template>
          <template #[`item.documentDate`]="{ item }">
            {{ item.documentDate | dateUserFormat() }}
          </template>
          <template #[`item.state`]="{ item }">
            {{ mapState(item.state) }}
          </template>
          <template #[`item.approver`]="{ item }">
            <span v-if="item.approver">
              {{ item.approver }}
              ({{ item.approvedDate | dateUserFormat() }})
            </span>
            <span v-else>
              -
            </span>
          </template>
          <template #[`item.receiver`]="{ item }">
            <span v-if="item.receiver">
              {{ item.receiver }}
              ({{ item.receivedDate | dateUserFormat() }})
            </span>
            <span v-else>
              -
            </span>
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn
              color="secondary"
              small
              icon
              :to="`/purchases/orders/${item.id}/pdf`">
              <v-icon small>
                mdi-magnify
              </v-icon>
            </v-btn>
            <v-btn
              color="secondary"
              small
              icon
              :to="`/purchases/orders/${item.id}?type=RM`">
              <v-icon small>
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
            <v-btn
              color="error"
              small
              icon
              @click="confirmRemoveItem(item)">
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="query.page"
          :length="pageCount"
          :disabled="loading"
          total-visible="7"
          color="secondary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import PurchaseOrdersProvider from '@/resources/PurchaseOrdersProvider'

const PurchaseOrdersService = new PurchaseOrdersProvider()

export default {
  components: {
    SearchBox
  },
  data () {
    return {
      headers: [
        {
          text: '',
          value: 'bcSync',
          align: 'center'
        },
        {
          text: 'รหัส',
          value: 'orderNo',
          align: 'center'
        },
        {
          text: 'วันที่',
          value: 'documentDate',
          align: 'center'
        },
        {
          text: 'สถานะ',
          value: 'state',
          align: 'center',
          width: '120'
        },
        {
          text: 'ผู้ขาย',
          value: 'vendor.name',
          sortable: false
        },
        {
          text: 'ผู้จัดทำ',
          value: 'createdBy.email',
          sortable: false
        },
        {
          text: 'ผู้อนุมัติ',
          value: 'approver',
          align: 'center',
          sortable: false
        },
        {
          text: 'ผู้รับสินค้า',
          value: 'receiver',
          align: 'center',
          sortable: false
        },
        {
          text: 'ตัวเลือก',
          value: 'action',
          align: 'center',
          sortable: false
        }
      ],
      itemSelect: [],
      items: [],
      query: {
        page: 1,
        limit: 10,
        search: ''
      },
      pageCount: 0,
      loading: false
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.getItems(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal'
    }),
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await PurchaseOrdersService.getItems({
          page: this.query.page,
          limit: this.query.limit,
          search: this.query.search,
          type: 'RM'
        })

        this.items = data.results
        this.pageCount = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    confirmRemoveItem (item) {
      this.setModal({
        value: true,
        title: 'Remove Item',
        message: `Do you want to remove ${item.orderNo}?`,
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.removeItem(item.id)
      })
    },
    onConfirmApprove () {
      this.setModal({
        value: true,
        title: 'Approve',
        message: 'Do you want to approve this purchase order',
        confirmText: 'Sure',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.approve()
      })
    },
    async approve () {
      try {
        this.loading = true

        await Promise.all(this.itemSelect.map((item) => PurchaseOrdersService.approveItem(item.id)))

        this.getItems()
      } catch (error) {
        console.error('removeItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async removeItem (id) {
      try {
        this.loading = true

        await PurchaseOrdersService.deleteItem(id)

        this.getItems(true)
      } catch (error) {
        console.error('removeItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    mapState (state) {
      switch (state) {
        case 'pending': return 'รอการอนุมัติ'
        case 'approved': return 'อนุมัติแล้ว'
        case 'received': return 'รับสินค้าแล้ว'
        case 'canceled': return 'ยกเลิก'
        default: return state
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
</style>
